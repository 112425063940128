import React, { useState, useEffect } from 'react';
import './Article.scss';
import '../../resources/main.scss';
import { blogService } from '../../services/BlogService';
import { Link, useParams, useNavigate } from 'react-router-dom';
import {
    FacebookShareButton,
    WhatsappShareButton
} from "react-share";
import Header from "../../components/Header/Header";
import DOMPurify from 'dompurify';
import Moment from 'react-moment';
import MetaTags from 'react-meta-tags';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link as ScrollLink } from 'react-scroll';
import MobileMenu from "../../components/Menu/MobileMenu";
import '../Homepage/Homepage.scss';
import '../../resources/main.scss';
import '../Blog/Blog.scss'
const Article = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [article, setArticle] = useState({});
    const [slug, setSlug] = useState('');
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        if (id) {
            blogService.getArticleBySlug(id)
                .then(response => {
                    setArticle(response.data);
                    setSlug(response.data.slug);
                })
                .catch(err => console.log(err));
        } else {
            navigate('/blog');
        }

        blogService.getArticles()
            .then(response => {
                setArticles(response.data.articles);
            })
            .catch(err => console.log(err));
    }, [id, navigate]);

    const copyLink = () => {
        navigator.clipboard.writeText(window.location.href);
        toast.success('Link-ul a fost copiat.', { position: 'top-center' });
    };
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const scrollToElementOnHome = (sectionId) => {
        navigate('/', { state: { scrollTo: sectionId } });
    };

    return (
        <div className='standard article blog hp'>
             <div className='hp__header'>
             <Link to='/' className='standard__link' onClick={scrollToTop}>   <img src='/images/logo.png' alt='logo' className='hp__logo' /></Link>
                <MobileMenu />
                <Header />
            </div>
            <div className='blog__banner'>
                <h4 className='blog__title'>Blog</h4>
            </div>
            <div className='article__container'>
             
                    {article.main_image && article.main_image.url ? (
                        <img src={process.env.REACT_APP_BLOG_API_URL + article.main_image.url} alt={article.main_image_alt} className='article__main-img' />
                    ) : (
                        <img src="/images/wedding.png" className='article__main-img' alt='article main' />
                    )}
                    <div className='article__img-shadow' />
                <div className='article__wrapper'>
                    <div className='blog__time-box article__time-box'>
                        <div className='blog__circle' />
                        <h5 className='blog__article-time'>
                            <Moment format="DD/MM/YYYY">
                                {article.updated_at}
                            </Moment>
                        </h5>
                    </div>
                    <div className='article__social'>
                        <FacebookShareButton url={`https://www.facemnunta.ro/app/article/${slug}`}>
                            <img src='/images/facebook-blue.png' alt='facebook' />
                        </FacebookShareButton>
                        <WhatsappShareButton url={window.location.href}>
                            <img src='/images/whatsapp.png' alt='whatsapp' />
                        </WhatsappShareButton>
                        <img src='/images/link.png' alt='link' onClick={copyLink} />
                    </div>
                    <h1 className='article__title'>
                        {article.title}
                    </h1>
                    <div className='article__info-wrapper'>
                        <p className='article__paragraf article__first-paragraph' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(article.description) }}></p>

                        {article.article_sections && article.article_sections.map((section, index) => (
                            section.tip !== 'image' ? (
                                React.createElement(
                                    section.fe_component,
                                    { className: `article__${section.tip}`, key: section.id, dangerouslySetInnerHTML: { __html: DOMPurify.sanitize(section.value) } }
                                )
                            ) : (
                                React.createElement(
                                    section.fe_component,
                                    {
                                        className: `article__${section.tip}`,
                                        key: section.id,
                                        src: section.image && section.image.url ? `${process.env.REACT_APP_BLOG_API_URL}${section.image.url}` : '/images/image-gym.jpg'
                                    }
                                )
                            )
                        ))}
                    </div>
                </div>
               

                <div className='article__actions-box'>
                    <div className='standard__link article__action' onClick={() => navigate(-1)}>
                        <img src='/images/back.png' alt='back' />
                        <h3>Înapoi</h3>
                    </div>

                    {/* {article.next_slug ? (
                        <Link className='standard__link article__action' to={`/articol/${article.next_slug}`}>
                            <h3>Articolul următor</h3>
                            <img src='/images/next.png' alt='next' />
                        </Link>
                    ) : null} */}
                </div>
                <div className='blog__all-articles article__all-articles'>
                    <h3 className='blog__subtitle article__blog-subtitle'>Alte articole</h3>
                    <div className='blog__line' />
                    <div className='article__articles-wrapper '>
                        {articles.slice(0, 3).map(articleItem => (
                            <div key={articleItem.id} className='blog__article-container article__single-article'>
                                <Link onClick={scrollToTop} to={`/article/${articleItem.slug}`} className='standard__link blog__article-whole'>
                                        {articleItem.main_image && articleItem.main_image.url ? (
                                            <img src={process.env.REACT_APP_BLOG_API_URL + articleItem.main_image.url} className='blog__article-img article__blog-img' alt='wedding' />
                                        ) : (
                                            <img src="/images/wedding.jpg" className='blog__article-img article__blog-img' alt='wedding' />
                                        )}
                                        <div className='blog__shadow' />
                                    <h3 className='blog__article-title'>{articleItem.title}</h3>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='hp__footer'>
                <div className='hp__footer-column'>
                    <img src='/images/logo-white.png' alt='logo' className='hp__footer-logo' />
                    <div className='hp__footer-social'>
                  <a href="https://www.facebook.com/asociatiaeduup/" target="_blank" className='standard__link'>
                     <img src='/images/facebook.png' alt='facebook' />
                  </a>
                  <a href="https://www.instagram.com/eduup.ro/" target="_blank" className='standard__link'>
                     <img src='/images/instagram.png' alt='instagram' />
                  </a>
                  <a href="https://www.linkedin.com/company/asociatia-eduup/" target="_blank" className='standard__link'>
                     <img src='/images/linkedin.png' alt='linkedin' />
                  </a>
                  <a href="https://www.youtube.com/@AsociatiaEduUP" target="_blank" className='standard__link'>
                     <img src='/images/youtube.png' alt='youtube' />
                  </a>

               </div>
                </div>

                <div className='hp__footer-column'>
                    <h4>EduUp</h4>
                    <a href='/documents/regulament-oficial-concurs.pdf' target='_blank' rel='noopener noreferrer' className='standard__link'>
                        <h5>Regulament concurs Summer Well 2024
                        </h5>
                    </a>
                    <ScrollLink to='despre' className='standard__link' smooth={true} duration={500}>
                        <h5 onClick={() => scrollToElementOnHome('despre')}>Despre EduUp</h5>
                    </ScrollLink>
                    <ScrollLink to='ce-inseamna' className='standard__link' smooth={true} duration={500}>
                        <h5>Ce înseamnă EduUp</h5>
                    </ScrollLink>
                </div>

                <div className='hp__footer-column'>
                    <h4>SkillUp</h4>

                    <ScrollLink to='mentori' className='standard__link' smooth={true} duration={500}>
                        <h5>SkillUp & Business</h5>
                    </ScrollLink>
                    <ScrollLink to='mentori' className='standard__link' smooth={true} duration={500}>
                        <h5 onClick={() => scrollToElementOnHome('mentori')}>Mentori</h5>
                    </ScrollLink>
                </div>

                <div className='hp__footer-column'>
                    <ScrollLink to='impact' className='standard__link' smooth={true} duration={500}>
                        <h5 onClick={() => scrollToElementOnHome('impact')}>Impact Social</h5>
                    </ScrollLink>
                    <ScrollLink to='parteneri' className='standard__link' smooth={true} duration={500}>
                        <h5 onClick={() => scrollToElementOnHome('parteneri')}>Parteneri</h5>
                    </ScrollLink>
                    <ScrollLink to='intrebari-frecvente' className='standard__link' smooth={true} duration={500}>
                        <h5 onClick={() => scrollToElementOnHome('intrebari-frecvente')}>Întrebări frecvente</h5>
                    </ScrollLink>
                </div>

                <div className='hp__footer-column'>
                    <h4>Contact</h4>
                    <h5>hello@eduup.ro</h5>
                    <a className='standard__link' href="tel:+40722280027">
                        <h5>+40722280027</h5>
                    </a>
                    <a href='https://oceanobe.com/' target='_blank' className='standard__link hp__oceanobe'>
                        <h5>Web platform done by the engineering experts @ OceanoBe Technology.</h5>
                    </a>
                </div>
            </div>     
              </div>
    );
};

export default Article;
