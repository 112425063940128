import React, { useState, useEffect } from 'react';
import '../Homepage/Homepage.scss';
import './ProgramBusiness.scss'
import '../../resources/main.scss';
import MobileMenu from "../../components/Menu/MobileMenu";
import { Link as ScrollLink } from 'react-scroll';
import { Link } from 'react-router-dom'
import Slider from "react-slick";
import { NextArrow, PrevArrow } from '../Homepage/CustomArrow';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import mentors from '../Homepage/mentors.json'
import { Modal, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import Header from "../../components/Header/Header";

import { formService } from "../../services/FormService";
const ProgramBusiness = () => {
    const { search } = useLocation();
    const [isAltDomeniuChecked, setIsAltDomeniuChecked] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 100);
    }, []);
    const navigate = useNavigate();
    const scrollToElementOnHome = (sectionId) => {
        navigate('/', { state: { scrollTo: sectionId } });
    };

    var settings = {
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        swipeable: true,
        dots: false,
        cssEase: 'linear',
        adaptiveHeight: false,
        centerPadding: '0',
        swipe: true,
        arrows: true,
        pauseOnHover: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    centerMode: false,
                }
            }
        ]
    };
    var partnerSettings = {
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeable: true,
        centerMode: true,
        dots: false,
        adaptiveHeight: true,
        centerMode: true,
        centerPadding: '0',
        swipe: true,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };
    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        email: '',
        phone: '',
        institution: '',
        city: '',
        interestDomain: [],
        gdprAgreement: false,
        newsletterSubscription: false,
        otherDomain: '',
        parent_phone:''
    });
    const [mentorsData, setMentors] = useState(mentors)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedMentor, setSelectedMentor] = useState(null);
    const mentorsWithClones = [
        ...mentorsData,
        ...mentorsData.slice(0, 3), // Cloning first 3 mentors for smooth transition
    ];
    const [isSkillUpOpen, setSkillUpOpen] = useState(false);

    const toggleSkillUpMenu = () => {
       setSkillUpOpen(!isSkillUpOpen);
    };
    const showModal = (mentor) => {
        setSelectedMentor(mentor);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setSelectedMentor(null);
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === 'checkbox' && name === 'interestDomain') {
            const newOptions = checked
                ? [...formData.interestDomain, value]
                : formData.interestDomain.filter(option => option !== value);

            setFormData((prevData) => ({
                ...prevData,
                interestDomain: newOptions,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: type === 'checkbox' ? checked : value,
            }));
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        setSubmitting(true);

        formService.contactForm(formData).then(response => {
            toast.success(`Cererea ta a fost trimisa cu succes.`);
            setSubmitting(false);
        })
            .catch(error => {
                console.log(error);
                setSubmitting(false);
                toast.error('A apărut o problemă, vă rugăm să încercați din nou.');
            });
    };
    useEffect(() => {
        const params = new URLSearchParams(search);
        const scrollToId = params.get('scrollTo');
        if (scrollToId) {
            const element = document.getElementById(scrollToId);
            if (element) {
                window.scrollTo({
                    top: element.offsetTop,
                    behavior: 'smooth',
                });
            }
        }
    }, [search]);
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className='standard hp form program'>
             <div className='hp__header'>
               <Link to='/' className='standard__link' onClick={scrollToTop}>
               <img src='/images/logo.png' alt='logo' className='hp__logo' />
               </Link>
                <MobileMenu />
               <Header/>
            </div>
        <div className='hp__banner program__banner'>
                <h2 className='program__title'>SkillUp & Business</h2>
                <div className='program__banner-info'>
                    <h3>by</h3>
                    <img src='/images/asus-white.png' alt='asus' />
                </div>

            </div>




            <div className='hp__program-section'>
                <h2 className='hp__section-title'>Despre program</h2>
                <p className='hp__section-description hp__about-description'><b>PROGRAMUL Skill  & Business</b> este inițiativa Asociației EDUUP, a cărei misiune este să ofere tinerilor experiențe practice și programe educaționale aplicate, derulate de către experți în business.</p>

            </div>

            <div className='hp__pros-section' id='skillup'>
                <div className='hp__skill-title'>
                    <h2 className='hp__section-title '>Skill<span>UP</span>& Business</h2>
                    <ScrollLink to='formular' className='standard__link standard__only-desktop' smooth={true} duration={500}>

                        <button className='hp__banner-button'>Aplică acum</button>
                    </ScrollLink>
                </div>

                <div className='hp__skill-right'>
                    <p className='hp__section-description'> <b>Obiectiv: </b>Definirea abilităților și punctelor forte ale elevilor și
                        crearea căilor lor de învățare cu ajutorul practicienilor</p>
                    <p className='hp__section-description'><b>Target: </b> Adolescentii din clasele a IX-a - a XII-a</p>
                    <p className='hp__section-description'><b>Grup: </b>  20-30 liceeni </p>
                    <p className='hp__section-description'><b>Durată: </b> 10 zile</p>
                    <ScrollLink to='formular' className='standard__link standard__only-mobile' smooth={true} duration={500}>
                        <button className='hp__banner-button apply-button'>Aplică acum</button>
                    </ScrollLink>
                    <div className="hp__pros-container">
                        <div className='hp__pro hp__pro-title standard__only-mobile'>
                            <h3>Beneficii</h3>

                        </div>
                        <div className="hp__pro hp__pro-yellow">
                            <h4>Business training cu practicieni</h4>
                        </div>
                        <div className="hp__pro hp__pro-blue">
                            <h4>Întâlniri cu antrepernori și experți în business</h4>
                        </div>
                        <div className="hp__pro hp__pro-red">
                            <h4>CV structurat cu expertiza unui specialist HR</h4>
                        </div>
                        <div className="hp__pro hp__pro-blue">
                            <h4>Abilități practice, mentalitate de antreprenor</h4>
                        </div>
                        <div className="hp__pro hp__pro-red">
                            <h4>Înscriere în grup Alumni cu acces gratuit la resurse educaționale</h4>
                        </div>
                        <div className='hp__pro hp__pro-title standard__only-desktop'>
                            <h3>Beneficii</h3>

                        </div>
                        <div className="hp__pro hp__pro-yellow">
                            <h4>Participare la evenimente de business</h4>
                        </div>
                        <div className="hp__pro hp__pro-blue">
                            <h4>la programe de voluntariat consistente și joburi sezoniere</h4>
                        </div>
                        <div className="hp__pro hp__pro-yellow">
                            <h4>Suport și ghidaj oferite de specialiști</h4>
                        </div>
                        <div className="hp__pro hp__pro-blue">
                            <h4>Premii puse la dispoziție de parteneri</h4>
                        </div>
                        <div className="hp__pro hp__pro-red">
                            <h4>O zi cu un CEO sau top management</h4>
                        </div>
                    </div>

                </div>
            </div >

            <div className='program__benefits'>
                <h3 className='program__benefits-title'>
                    Ce <span>obții</span> in urma programului
                </h3>
                <div className='program__benefits-wrapper'>
                    <div className='program__benefits-list'>
                        <h4>1. Pregătire de business cu experți de top, 10 întâlniri practic</h4>
                        <h4>2. Proiect final coordonat de unul dintre mentori</h4>
                        <h4>3. Diplomă de participare și scrisoare de recomandare</h4>
                        <h4>4. Structurare CV și recomandări</h4>
                        <h4>5. Participare în grup Alumni format din adolescenți ai cohortelor de Business</h4>
                    </div>
                    <div className='program__benefits-list'>
                        <h4>6. Invitații la evenimentele de business din anul 2025</h4>
                        <h4>7. Participare la concursuri cu premii oferite de parteneri</h4>
                        <h4>8. Implicare în proiecte de business, ca voluntari, timp de 1 an, inclusiv în ”Gala Campionilor în Educație”(Oct. 2025)</h4>
                        <h4>9. Goodie bag-uri oferite de partenerul principal, ASUS; o porție de sănătate oferită de Artesana & multe alte surprize</h4>
                    </div>
                </div>
            </div>

            <div className='hp__mentors-section' id='mentori'>
                <h2 className='hp__section-title hp__mentors-title'>Mentorii Skill<span>UP</span>& <span className='yellow-span'>Business</span> </h2>
                <Slider  {...settings} className='hp__slider '>
                    {mentorsData.map(mentor => (
                        <div className='hp__mentor' key={mentor.key}>
                            <img src={mentor.imageUrl} alt='mentor' />
                            <h4>{mentor.name}</h4>
                            <h5>{mentor.function}</h5>
                            <h5>{mentor.brand}</h5>
                            <h5><i>{mentor.tags}</i></h5>
                            {mentor.bio && (
                                <h5 type='primary' onClick={() => showModal(mentor)} className='hp__see-bio'>
                                    Află mai multe
                                </h5>
                            )}                  </div>
                    ))}

                </Slider>

            </div>

            <div className='hp__resources'>
                <div className='hp__resources-text'>
                    <h3>Vezi<span className='hp__purple'> programul  </span>și temele de dezbatere ale  <span className='hp__pink'>experților în business</span></h3>
                </div>
                <button onClick={() => window.open('/documents/agenda.pdf', '_blank')}>Vezi programul</button>
              <Link to='/' className='standard__link'>
              { /* <button className='hp__anterior-programs'>Vezi programele anterioare</button> */}
              </Link>

            </div>

            <div className='program__packages'>
                <h3 className='program__packages-title'>Pachete disponibile</h3>
                <div className='program__packages-wrapper'>
                    <div className='program__package program__pink-package'>
                        <h4>Pachet complet 10 workshop-uri</h4>
                        <h3>999 lei</h3>
                    </div>
                    <div className='program__package program__blue-package'>
                        <h4>Pachet 5 workshop-uri</h4>
                        <h3>499   lei</h3>
                    </div>
                </div>
                <h4 className='program__asterix'>*Aplică pentru <b>bursă</b>! Mergi la <span onClick={() => scrollToElementOnHome('impact')}>“Impact social”</span>, urmează pașii și află dacă ești eligibil.</h4>
            </div>




            <div className='hp__form-section' id='formular'>
                <img src='/images/form-img.png' className='hp__form-img' />
                <div className='hp__form-right'>
                    <h3 className='hp__form-subtitle program__form-title'>Dacă vrei să te înscrii,</h3>

                    <h3 className='hp__form-subtitle program__form-subtitle'>completează câmpurile de mai jos:</h3>
                    <form onSubmit={handleSubmit} className='hp__form'>
                        <div className='hp__form-box'>
                            <input
                                type="text"
                                name="name"
                                required
                                placeholder="Nume *"
                                value={formData.name}
                                onChange={handleChange}
                            />
                            <input
                                type="text"
                                name="surname"
                                required
                                placeholder="Prenume *"
                                value={formData.surname}
                                onChange={handleChange}
                            />
                        </div>
                            <input
                                type="email"
                                required
                                name="email"
                                placeholder="Email *"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        <div className='hp__form-box'>

                            <input
                                type="number"
                                required
                                name="phone"
                                placeholder="Telefon elev*"
                                value={formData.phone}
                                onChange={handleChange}
                            />
                              <input
                                type="number"
                                required
                                name="parent_phone"
                                placeholder="Telefon parinte*"
                                value={formData.parent_phone}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='hp__form-box'>
                            <input
                                type="text"
                                required
                                name="institution"
                                placeholder="Instituția de învățământ *"
                                value={formData.institution}
                                onChange={handleChange}
                            />
                            <input
                                type="text"
                                required
                                name="city"
                                placeholder="Oras *"
                                value={formData.city}
                                onChange={handleChange}
                            />
                        </div>
                        <label className='form__label hp__domain-label'>Domenii de interes:</label>

                        <div className='form__checkbox-container'>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="interestDomain"
                                    value="Drept"
                                    checked={formData.interestDomain.includes("Drept")}
                                    onChange={handleChange}
                                />
                                <h5>Drept</h5>
                            </div>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="interestDomain"
                                    value="Medicina"
                                    checked={formData.interestDomain.includes("Medicina")}
                                    onChange={handleChange}
                                />
                                <h5>Medicina</h5>
                            </div>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="interestDomain"
                                    value="Psihologie"
                                    checked={formData.interestDomain.includes("Psihologie")}
                                    onChange={handleChange}
                                />
                                <h5>Psihologie</h5>
                            </div>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="interestDomain"
                                    value="IT"
                                    checked={formData.interestDomain.includes("IT")}
                                    onChange={handleChange}
                                />
                                <h5>IT</h5>
                            </div>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="interestDomain"
                                    value="Business"
                                    checked={formData.interestDomain.includes("Business")}
                                    onChange={handleChange}
                                />
                                <h5>Business</h5>
                            </div>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="interestDomain"
                                    value="Jurnalism"
                                    checked={formData.interestDomain.includes("Jurnalism")}
                                    onChange={handleChange}
                                />
                                <h5>Jurnalism</h5>
                            </div>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="interestDomain"
                                    value="Marketing"
                                    checked={formData.interestDomain.includes("Marketing")}
                                    onChange={handleChange}
                                />
                                <h5>Marketing</h5>
                            </div>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="interestDomain"
                                    value="Design Grafic"
                                    checked={formData.interestDomain.includes("Design Grafic")}
                                    onChange={handleChange}
                                />
                                <h5>Design Grafic</h5>
                            </div>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="interestDomain"
                                    value="Design interior"
                                    checked={formData.interestDomain.includes("Design interior")}
                                    onChange={handleChange}
                                />
                                <h5>Design interior</h5>
                            </div>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="interestDomain"
                                    value="Inginerie"
                                    checked={formData.interestDomain.includes("Inginerie")}
                                    onChange={handleChange}
                                />
                                <h5>Inginerie</h5>
                            </div>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="interestDomain"
                                    value="Arhitectură"
                                    checked={formData.interestDomain.includes("Arhitectură")}
                                    onChange={handleChange}
                                />
                                <h5>Arhitectură</h5>
                            </div>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="interestDomain"
                                    value="Alta"
                                    checked={formData.interestDomain.includes("Alta")}
                                    onChange={handleChange}
                                />
                                <h5>Alt domeniu de interes</h5>
                            </div>
                        </div>
                        {formData.interestDomain.includes("Alta") ? <input
                            type="text"
                            name="otherDomain"
                            placeholder="Domeniu de interes"
                            value={formData.otherDomain}
                            onChange={handleChange}
                        /> : ''}
                        <div className='hp__checkbox'>
                            <input
                                type="checkbox"
                                name="gdprAgreement"
                                checked={formData.gdprAgreement}
                                onChange={handleChange}
                            />
                            <h5>Sunt de acord cu normele GDPR de prelucrare a datelor personale.</h5>
                        </div>
                        <div className='hp__checkbox'>
                            <input
                                type="checkbox"
                                name="newsletterSubscription"
                                checked={formData.newsletterSubscription}
                                onChange={handleChange}
                            />
                            <h5> Sunt de acord să mă abonez la newsletterul
                                EduUP.</h5>
                        </div>
                        <button type="submit" className={`hp__form-submit ${(!formData.newsletterSubscription || !formData.gdprAgreement) ? 'disabled' : ''}`}
                            disabled={submitting || !formData.newsletterSubscription || !formData.gdprAgreement}>Înscriere</button>
                    </form>
                </div>

            </div>





            <div className='hp__footer'>
                <div className='hp__footer-column'>
                    <img src='/images/logo-white.png' alt='logo' className='hp__footer-logo' />
                    <div className='hp__footer-social'>
                  <a href="https://www.facebook.com/asociatiaeduup/" target="_blank" className='standard__link'>
                     <img src='/images/facebook.png' alt='facebook' />
                  </a>
                  <a href="https://www.instagram.com/eduup.ro/" target="_blank" className='standard__link'>
                     <img src='/images/instagram.png' alt='instagram' />
                  </a>
                  <a href="https://www.linkedin.com/company/asociatia-eduup/" target="_blank" className='standard__link'>
                     <img src='/images/linkedin.png' alt='linkedin' />
                  </a>
                  <a href="https://www.youtube.com/@AsociatiaEduUP" target="_blank" className='standard__link'>
                     <img src='/images/youtube.png' alt='youtube' />
                  </a>

               </div>
                </div>

                <div className='hp__footer-column'>
                    <h4>EduUp</h4>
                    <a href='/documents/regulament-oficial-concurs.pdf' target='_blank' rel='noopener noreferrer' className='standard__link'>
                        <h5>Regulament concurs Summer Well 2024
                        </h5>
                    </a>
                    <ScrollLink to='despre' className='standard__link' smooth={true} duration={500}>
                        <h5 onClick={() => scrollToElementOnHome('despre')}>Despre EduUp</h5>
                    </ScrollLink>
                    <ScrollLink to='ce-inseamna' className='standard__link' smooth={true} duration={500}>
                        <h5>Ce înseamnă EduUp</h5>
                    </ScrollLink>
                </div>

                <div className='hp__footer-column'>
                    <h4>SkillUp</h4>

                    <ScrollLink to='mentori' className='standard__link' smooth={true} duration={500}>
                        <h5>SkillUp & Business</h5>
                    </ScrollLink>
                    <ScrollLink to='mentori' className='standard__link' smooth={true} duration={500}>
                        <h5 onClick={() => scrollToElementOnHome('mentori')}>Mentori</h5>
                    </ScrollLink>
                </div>

                <div className='hp__footer-column'>
                    <ScrollLink to='impact' className='standard__link' smooth={true} duration={500}>
                        <h5 onClick={() => scrollToElementOnHome('impact')}>Impact Social</h5>
                    </ScrollLink>
                    <ScrollLink to='parteneri' className='standard__link' smooth={true} duration={500}>
                        <h5 onClick={() => scrollToElementOnHome('parteneri')}>Parteneri</h5>
                    </ScrollLink>
                    <ScrollLink to='intrebari-frecvente' className='standard__link' smooth={true} duration={500}>
                        <h5 onClick={() => scrollToElementOnHome('intrebari-frecvente')}>Întrebări frecvente</h5>
                    </ScrollLink>
                </div>

                <div className='hp__footer-column'>
                    <h4>Contact</h4>
                    <h5>hello@eduup.ro</h5>
                    <a className='standard__link' href="tel:+40722280027">
                        <h5>+40722280027</h5>
                    </a>
                    <a href='https://oceanobe.com/' target='_blank' className='standard__link hp__oceanobe'>
                        <h5>Web platform done by the engineering experts @ OceanoBe Technology.</h5>
                    </a>
                </div>

                <Modal
                    title={selectedMentor ? selectedMentor.name : ''}
                    visible={isModalVisible}
                    onCancel={handleCancel}
                    footer={[
                        <Button key="close" onClick={handleCancel}>
                            Închide
                        </Button>,
                    ]}
                >
                    {selectedMentor && (
                        <>
                            <p className='hp__mentor-bio'>{selectedMentor.bio}</p>
                            <a href={selectedMentor.linkedinUrl} target="_blank" rel="noopener noreferrer">
                                Vezi profilul LinkedIn
                            </a>
                        </>
                    )}
                </Modal>
            </div>
        </div >
    )
}
export default ProgramBusiness
