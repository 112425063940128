import React, { useState } from 'react';
import { Modal, Form, Input, InputNumber, Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import AttachementField from "../AttachementField/AttachementField";
import { ToastContainer, toast } from 'react-toastify';
import './ImpactForm.scss';
import { formService } from "../../services/FormService";

const ImpactForm = ({ isOpen, onClose }) => {
   const [form] = Form.useForm();
   const [userData, setUserData] = useState({
    name:'',
    surname:'',
    phone:'',
    email:'',
    cv:'',
    essay:''
   });
   const [submitting, setSubmitting] = useState(false);

   const onSubmit = (values) => {
     console.log('aaaa');
      setUserData(values); // Save form values to state
      console.log('User Data:', values);
      onClose(); // Close the modal
   };

   const cvDrop = (e) => {
      let userNewData = { ...userData};

      userNewData.cv = e.file;

      setUserData(userNewData);
   };

   const essayDrop = (e) => {
      let userNewData = { ...userData};

      userNewData.essay = e.file;

      setUserData(userNewData);
   };

   const onFinish = (values) => {
     let newUserData = { ...userData };

     newUserData = {
       ...newUserData,
       name: values.name,
       last_name: values.surname,
       phone: values.phone,
       email: values.email,
     }

     setSubmitting(true);

     console.log(newUserData);

     formService.scolarshipApplicantForm(newUserData)
       .then(response => {
           toast.success(`Cererea ta a fost trimisa cu succes.`);
           setSubmitting(false);
       })
         .catch(error => {
             setSubmitting(false);
             toast.error('A apărut o problemă, vă rugăm să încercați din nou.');
         });
   }

   return (
      <Modal
         title="Aplică pentru bursă"
         open={isOpen}
         onCancel={onClose}
         footer={null}
      >
         <Form
            form={form}
            layout="vertical"
            onSubmit={onSubmit}
            onFinish={onFinish}
         >
            <Form.Item
               label="Nume"
               name="name"
               rules={[{ required: true, message: 'Introduceți numele!' }]}
            >
               <Input placeholder="Nume" />
            </Form.Item>
            <Form.Item
               label="Prenume"
               name="surname"
               rules={[{ required: true, message: 'Introduceți prenumele!' }]}
            >
               <Input placeholder="Prenume" />
            </Form.Item>
            <Form.Item
               label="Email"
               name="email"
               rules={[
                  { required: true, message: 'Introduceți emailul!' },
                  { type: 'email', message: 'Introduceți un email valid!' }
               ]}
            >
               <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
               label="Telefon"
               name="phone"
               rules={[{ required: true, message: 'Introduceți numărul de telefon!' }]}
            >
               <InputNumber class="input-number" placeholder="Telefon" />
            </Form.Item>
            <Form.Item
               label="CV"
               name="cv"
               valuePropName="fileListCv"
               getValueFromEvent={cvDrop}
               extra="Aplicanții fără CV nu vor fi procesați."
            >
               <Upload beforeUpload={() => false} maxCount={1} accept="application/pdf">
                  <Button icon={<UploadOutlined />}>Încarcă CV</Button>
               </Upload>
            </Form.Item>
            <Form.Item
               label="Eseu"
               name="essay"
               valuePropName="fileListEssay"
               getValueFromEvent={essayDrop}
               extra="Aplicanții fără eseu nu vor fi procesați."
            >
               <Upload beforeUpload={() => false} maxCount={1} accept="application/pdf">
                  <Button icon={<UploadOutlined />}>Încarcă Eseu</Button>
               </Upload>
            </Form.Item>
            <Form.Item>
               <Button type="primary" htmlType="submit" block disabled={submitting}>
                  Trimite
               </Button>
            </Form.Item>
         </Form>
      </Modal>
   );
};

export default ImpactForm;
