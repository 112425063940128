import axios from 'axios';

export const formService={
    contactForm,
    festivalForm,
    generalForm,
    scolarshipApplicantForm
}
function generalForm(userData) {
  let url = `${process.env.REACT_APP_API_URL}/api/contact_forms`;
  const requestOptionsPost = {
    method: 'POST',
    url,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json'
    },
    data: {
      contact_form: {
        name: userData.name,
        last_name: userData.surname,
        email: userData.email,
        phone: userData.phone,
        message:userData.message,
        gdpr_agreement:userData.gdprAgreement,
        newsletter_subscription:userData.newsletterSubscription
      }
    }
  };

  return axios(requestOptionsPost);
}
function contactForm(userData) {
    let url = `${process.env.REACT_APP_API_URL}/api/program_applicants`;
    const requestOptionsPost = {
      method: 'POST',
      url,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      },
      data: {
        program_applicant: {
          first_name: userData.name,
          last_name: userData.surname,
          email: userData.email,
          city:userData.city,
          phone: userData.phone,
          learning_institute:userData.institution,
          gdpr_agreement:userData.gdprAgreement,
          newsletter_subscription:userData.newsletterSubscription,
          domains:userData.interestDomain,
          other_domains:userData.otherDomain,
          tip_questionnaire: 'skillUpBussiness',
          parent_phone:userData.parent_phone
        }
      }
    };

    return axios(requestOptionsPost);
  }
  function festivalForm(userData) {
    let url = `${process.env.REACT_APP_API_URL}/api/summerwell_applicants`;
    const requestOptionsPost = {
      method: 'POST',
      url,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      },
      data: {
        summerwell_applicant: {
          first_name: userData.firstName,
          last_name: userData.lastName,
          email: userData.email,
          grade:userData.grade,
          phone: userData.phone,
          challenges:userData.challenges,
          platform_preferences:userData.platformPreferences,
          information:userData.information,
          faculty_options:userData.facultyOptions,
          gdpr_agreement:userData.gdprAgreement,
          newsletter_subscription:userData.newsletterSubscription,
          other_challenges:userData.otherChallenges,
          other_platform_preferences:userData.otherPlatformPreferences,
          other_faculty_options:userData.otherFacultyOptions
        }
      }
    };

    return axios(requestOptionsPost);
  }

  function scolarshipApplicantForm(userData) {
    let url = `${process.env.REACT_APP_API_URL}/api/scolarship_applicants`;
    const requestOptionsPost = {
        method: 'POST',
        url,
        data:createBodyRequest(userData)
      };

      return axios(requestOptionsPost);
  }

  function createBodyRequest(userData) {
    console.log('aaa');
    console.log(userData);
     let formData = new FormData();

     formData.append('scolarship_applicant[name]', userData.name);
     formData.append('scolarship_applicant[last_name]', userData.last_name);
     formData.append('scolarship_applicant[email]', userData.email);
     formData.append('scolarship_applicant[phone]', userData.phone);

     const documentCV = userData.cv;

     if (documentCV && documentCV.url !== null) {
       if (documentCV.url) {
         formData.append(`scolarship_applicant[cv]`, documentCV.url);
       } else {
         formData.append(`scolarship_applicant[cv]`, documentCV);
       }
     }

     const documentEssay = userData.essay;

     if (documentEssay && documentEssay.url !== null) {
       if (documentEssay.url) {
         formData.append(`scolarship_applicant[essay]`, documentEssay.url);
       } else {
         formData.append(`scolarship_applicant[essay]`, documentEssay);
       }
     }

     return formData;
   }
