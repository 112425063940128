import React, { useState, useEffect } from 'react';
import '../Homepage/Homepage.scss';
import './AnteriorPrograms.scss'
import '../../resources/main.scss';
import MobileMenu from "../../components/Menu/MobileMenu";
import { Link as ScrollLink } from 'react-scroll';
import { Link } from 'react-router-dom'
import { Modal, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Select } from 'antd';
import mentors from '../Homepage/mentors.json'

const { Option } = Select;

const programs = [
    {
        id: 1,
        name: 'SkillUP & Business by ASUS Business',
        mentors: '/images/mentori-program1.png',
        agenda: '/agende/agenda-program1.pdf',
        gallery: '/galerii/galerie-program1',
        testimonials: '/testimoniale/testimoniale-program1'
    },
    {
        id: 2,
        name: 'Programul 2',
        mentors: '/images/mentori-program2.png',
        agenda: '/agende/agenda-program2.pdf',
        gallery: '/galerii/galerie-program2',
        testimonials: '/testimoniale/testimoniale-program2'
    }
];
const AnteriorPrograms = () => {

    const navigate = useNavigate();
    const scrollToElementOnHome = (sectionId) => {
        navigate('/', { state: { scrollTo: sectionId } });
    };

    const [selectedProgram, setSelectedProgram] = useState(programs[0]);



    const [isSkillUpOpen, setSkillUpOpen] = useState(false);

    const toggleSkillUpMenu = () => {
        setSkillUpOpen(!isSkillUpOpen);
    };

    const handleChange = (value) => {
        const program = programs.find((p) => p.id === value);
        setSelectedProgram(program);
    };


    return (
        <div className='standard hp form program anterior'>
            <div className='hp__header'>
                <Link to='/' className='standard__link'>
                    <img src='/images/logo.png' alt='logo' className='hp__logo' />
                </Link>
                <MobileMenu />
                <div className='hp__desktop-menu'>
                    <ScrollLink to='despre' className='standard__link' smooth={true} duration={500}>
                        <h4 onClick={() => scrollToElementOnHome('despre')}>Despre </h4>
                    </ScrollLink>
                    <ScrollLink to='parteneri' className='standard__link' smooth={true} duration={500}>
                        <h4 onClick={() => scrollToElementOnHome('parteneri')}>Parteneri</h4>
                    </ScrollLink>
                    <ScrollLink to='impact' className='standard__link' smooth={true} duration={500}>
                        <h4 onClick={() => scrollToElementOnHome('impact')}>Impact social</h4>
                    </ScrollLink>
                    <ScrollLink to='intrebari-frecvente' className='standard__link' smooth={true} duration={500}>
                        <h4 onClick={() => scrollToElementOnHome('intrebari-frecvente')}>FAQ</h4>
                    </ScrollLink>
                    <ScrollLink to='contact' className='standard__link' smooth={true} duration={500}>
                        <h4 onClick={() => scrollToElementOnHome('contact')}>Contact</h4>
                    </ScrollLink>
                </div>
            </div>
            <div className='hp__banner program__banner anterior__banner'>
                <h2 className='program__title'>Programe anterioare</h2>
              

            </div>
            <div className="anterior__custom-select">
            <select onChange={(e) => handleChange(Number(e.target.value))} defaultValue={programs[0].id}>
                {programs.map((program) => (
                    <option key={program.id} value={program.id}>
                        {program.name}
                    </option>
                ))}
            </select>
            <img src="/images/down.png" alt='down' className='anterior__down'/>
        </div>
            <div className="program-details">
                <h3>{selectedProgram.name}</h3>
                <div className="anterior__mentors">
                    <h4>Mentori</h4>
                    <div className="anterior__mentor-list">
                        {mentors.map((mentor, index) => (
                            <div key={index} className="anterior__mentor-card">
                                <img
                                    src={mentor.imageUrl}
                                    alt={mentor.name}
                                    className="anterior__mentor-image"
                                />
                                <h5 className="anterior__mentor-name">{mentor.name}</h5>
                                <p className="anterior__mentor-role">{mentor.function} {mentor.brand}</p>
                            </div>
                        ))}
                    </div>
                </div>                <div className="program-actions">
                    <a href={selectedProgram.agenda} target="_blank" rel="noopener noreferrer">
                        Vezi agenda
                    </a>
                    <a href={selectedProgram.gallery} target="_blank" rel="noopener noreferrer">
                        Vezi galeria
                    </a>
                    <a href={selectedProgram.testimonials} target="_blank" rel="noopener noreferrer">
                        Vezi testimonialele
                    </a>
                </div>
            </div>

            <div className='hp__footer'>
                <div className='hp__footer-column'>
                    <img src='/images/logo-white.png' alt='logo' className='hp__footer-logo' />
                    <div className='hp__footer-social'>
                        <a href="https://www.facebook.com/asociatiaeduup/" target="_blank" className='standard__link'>
                            <img src='/images/facebook.png' alt='facebook' />
                        </a>
                        <a href="https://www.instagram.com/eduup.ro/" target="_blank" className='standard__link'>
                            <img src='/images/instagram.png' alt='instagram' />
                        </a>

                    </div>
                </div>

                <div className='hp__footer-column'>
                    <h4>EduUp</h4>

                    <ScrollLink to='despre' className='standard__link' smooth={true} duration={500}>
                        <h5 onClick={() => scrollToElementOnHome('despre')}>Despre EduUp</h5>
                    </ScrollLink>
                    <ScrollLink to='ce-inseamna' className='standard__link' smooth={true} duration={500}>
                        <h5>Ce înseamnă EduUp</h5>
                    </ScrollLink>
                </div>

                <div className='hp__footer-column'>
                    <h4>SkillUp</h4>

                    <ScrollLink to='mentori' className='standard__link' smooth={true} duration={500}>
                        <h5>SkillUp & Business</h5>
                    </ScrollLink>
                    <ScrollLink to='mentori' className='standard__link' smooth={true} duration={500}>
                        <h5 onClick={() => scrollToElementOnHome('mentori')}>Mentori</h5>
                    </ScrollLink>
                </div>

                <div className='hp__footer-column'>
                    <ScrollLink to='impact' className='standard__link' smooth={true} duration={500}>
                        <h5 onClick={() => scrollToElementOnHome('impact')}>Impact Social</h5>
                    </ScrollLink>
                    <ScrollLink to='parteneri' className='standard__link' smooth={true} duration={500}>
                        <h5 onClick={() => scrollToElementOnHome('parteneri')}>Parteneri</h5>
                    </ScrollLink>
                    <ScrollLink to='intrebari-frecvente' className='standard__link' smooth={true} duration={500}>
                        <h5 onClick={() => scrollToElementOnHome('intrebari-frecvente')}>Întrebări frecvente</h5>
                    </ScrollLink>
                </div>

                <div className='hp__footer-column'>
                    <h4>Contact</h4>
                    <h5>asociatiaeduup@gmail.com</h5>
                    <a className='standard__link' href="tel:+40722280027">
                        <h5>+40722280027</h5>
                    </a>
                    <a href='https://oceanobe.com/' target='_blank' className='standard__link hp__oceanobe'>
                        <h5>Web platform done by the engineering experts @ OceanoBe Technology.</h5>
                    </a>
                </div>


            </div>
        </div >
    )
}
export default AnteriorPrograms
