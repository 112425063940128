import React, { useState, useEffect } from 'react';
import './Homepage.scss';
import '../../resources/main.scss';
import MobileMenu from "../../components/Menu/MobileMenu";
import { Link as ScrollLink } from 'react-scroll';
import { Link } from 'react-router-dom'
import FAQSection from "./FaqSection";
import faqs from './faqs.json'
import Slider from "react-slick";
import { NextArrow, PrevArrow } from './CustomArrow';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import mentors from './mentors.json'
import { Modal, Button } from 'antd';
import { animateScroll as scroll, scroller } from 'react-scroll';
import Header from "../../components/Header/Header";
import ImpactForm from "../../components/ImpactForm/ImpactForm";
import { formService } from "../../services/FormService";
const Homepage = () => {
   const { search } = useLocation();
   const [isAltDomeniuChecked, setIsAltDomeniuChecked] = useState(false);
   const [isModalOpen, setIsModalOpen] = useState(false);

   const showFormModal = () => {
      setIsModalOpen(true);
   };
   const closeModal = () => {
      setIsModalOpen(false);
   };
   useEffect(() => {
      setTimeout(() => {
         window.dispatchEvent(new Event('resize'));
      }, 100);
   }, []);
   var settings = {
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 2000,
      pauseOnHover: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      swipeable: true,
      dots: false,
      cssEase: 'linear',
      adaptiveHeight: false,
      centerPadding: '0',
      swipe: true,
      arrows: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
         {
            breakpoint: 768,
            settings: {
               slidesToShow: 1,
               centerMode: false,
            }
         }
      ]
   };
   var partnerSettings = {
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeable: true,
      centerMode: true,
      dots: false,
      adaptiveHeight: true,
      centerMode: true,
      centerPadding: '0',
      swipe: true,
      arrows: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />
   };
   const [formData, setFormData] = useState({
      name: '',
      surname: '',
      email: '',
      phone: '',
      institution: '',
      city: '',
      interestDomain: [],
      gdprAgreement: false,
      newsletterSubscription: false,
      otherDomain: '',
      message:''
   });
   const [mentorsData, setMentors] = useState(mentors)
   const [isModalVisible, setIsModalVisible] = useState(false);
   const [selectedMentor, setSelectedMentor] = useState(null);
   const [submitting, setSubmitting] = useState(false);

   const currentLocation = useLocation();
   useEffect(() => {
      if (currentLocation.state?.scrollTo) {
          scroller.scrollTo(currentLocation.state.scrollTo, {
              duration: 800,
              delay: 0,
              smooth: 'easeInOutQuart',
          });
      }
  }, [currentLocation.state]);

   const mentorsWithClones = [
      ...mentorsData,
      ...mentorsData.slice(0, 3), // Cloning first 3 mentors for smooth transition
   ];
   const showModal = (mentor) => {
      setSelectedMentor(mentor);
      setIsModalVisible(true);
   };

   const handleCancel = () => {
      setIsModalVisible(false);
      setSelectedMentor(null);
   };

   const handleChange = (e) => {
      const { name, value, type, checked } = e.target;

      if (type === 'checkbox' && name === 'interestDomain') {
         const newOptions = checked
            ? [...formData.interestDomain, value]
            : formData.interestDomain.filter(option => option !== value);

         setFormData((prevData) => ({
            ...prevData,
            interestDomain: newOptions,
         }));
      } else {
         setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
         }));
      }
   };
   const handleSubmit = (e) => {
      e.preventDefault();
      setSubmitting(true);

      formService.generalForm(formData).then(response => {
        setSubmitting(false);
         toast.success(`Cererea ta a fost trimisa cu succes.`);
      })
         .catch(error => {
            console.log(error);
            setSubmitting(false);
            toast.error('A apărut o problemă, vă rugăm să încercați din nou.');
         });
   };
   useEffect(() => {
      const params = new URLSearchParams(search);
      const scrollToId = params.get('scrollTo');
      if (scrollToId) {
         const element = document.getElementById(scrollToId);
         if (element) {
            window.scrollTo({
               top: element.offsetTop,
               behavior: 'smooth',
            });
         }
      }
   }, [search]);
   const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
  };
   return (
      <div className='standard hp form'>
         <div className='hp__header'>
            <img src='/images/logo.png' alt='logo' className='hp__logo' />
            <MobileMenu />
           <Header/>
         </div>

         <div className='hp__banner'>
            <div className='hp__banner-info'>
               <h2 className='hp__title'>Educație aplicată pentru tineri</h2>
               <h3 className='hp__subtitle'>Construim punți către viitor prin oferirea de experiențe practice și programe educaționale aplicate pentru tineri</h3>
               <ScrollLink to='programs' className='standard__link' smooth={true} duration={500}>
                  <button className='hp__banner-button'>
                     Aplică acum
                  </button>
               </ScrollLink>
            </div>


            <div className='hp__benefits benefits'>
               <div className='hp__benefit'>
                  <img src='/images/benefit-icon.png' alt='hp__benefit' />
                  <h3>Abilități practice</h3>
                  <h4>Ghidați de specialiști pe diferite domenii de activitate</h4>
               </div>
               <div className='hp__benefit'>
                  <img src='/images/benefit-icon.png' alt='hp__benefit' />
                  <h3>Întâlniri cu antreprenori</h3>
                  <h4>CEO și top management ale companiilor</h4>
               </div>
               <div className='hp__benefit'>
                  <img src='/images/benefit-icon.png' alt='hp__benefit' />
                  <h3>Resurse educaționale</h3>
                  <h4>Acces gratuit la resursele educaționale timp de 1 an</h4>
               </div>
            </div>
         </div>

         <div className='hp__about' id='despre'>
            <h2 className='hp__section-title'>Despre Edu<span>UP</span></h2>
            <p className='hp__section-description'>Asociația EduUP oferă o nouă perspectivă, un context extracuricular ce are ca scop dezvoltarea abilităților și competențelor tinerilor pentru a putea face față schimbărilor pieței și a naviga către succes. Creăm un mediu educațional unde tinerii sunt încurajați să-și exploreze potențialul, să inoveze și să colaboreze.</p>
         </div>

         <div className='hp__meaning' id='ce-inseamna'>
            <h2 className='hp__section-title hp__meaning-title'>Ce înseamnă Edu<span>UP</span></h2>
            <div className='hp__meaning-wrapper'>
               <div className='hp__meaning-box'>
                  <h4>Misiune</h4>
                  <p>Punem accent pe îmbunătățirea aptitudinilor practice și a lucrului în echipă, cultivăm gândirea critică, creativitatea și adaptabilitatea, abilități cheie în lumea dinamică, în continuă schimbare.</p>
                  <img src='/images/meaning1.png' />
               </div>
               <div className='hp__meaning-box'>
                  <h4>Viziune</h4>
                  <p>Creăm o comunitate de tineri instruiți de practicieni care îi inspiră și le oferă experiențe aplicate.</p>
                  <img src='/images/meaning2.png' />
               </div>
               <div className='hp__meaning-box'>
                  <h4>Valori</h4>
                  <p>Inovația, incluziunea, colaborarea: promovăm abordări noi, respectăm diversitatea și creăm echipe cu interese comune pentru atingerea potențialului maxim.</p>
                  <img src='/images/meaning3.png' />
               </div>
            </div>

         </div>
         {/*
         <div className='hp__meaning'>
            <div className='hp__meaning-box hp__info-box'>
               <h2>Skill<span>UP</span></h2>
               <Link to='/' className='standard__link'><p>Înscrieri deschise</p></Link>
            </div>
            <div className='hp__meaning-box hp__info-box'>
               <h2>Edu<span className='yellow-span'>HUB</span></h2>
               <p>DISPONIBIL CURÂND</p>
            </div>
            <div className='hp__meaning-box hp__info-box'>
               <h2>Masterclass</h2>
               <p>DISPONIBIL CURÂND</p>
            </div>
         </div> */}

         <div className='hp__program-section'>
            <h2 className='hp__section-title'>Despre programul Skill<span>UP</span></h2>
            <p className='hp__section-description hp__about-description'>În documentarea acestui program, am creat un grup de lucru format din reprezentanți ai elevilor din Municipiul București alături de alți elevi implicați civic și am dezbătut aspectele cu care se confruntă ei în ceea ce privește domeniul educațional.</p>
            <h3 className='hp__program-title'>Provocările tinerei generații</h3>
            <div className='hp__program-wrapper'>
               <div className='hp__program-box'>
                  <h2>01</h2>
                  <h3>Lipsa educației practice</h3>
                  <p>Adolescenții asimilează informații și conținut teoretic, iar de cele mai multe ori acestea nu sunt însoțite de activități practice.</p>
               </div>
               <div className='hp__program-box yellow yello-cadran'>
                  <h2>02</h2>
                  <h3>Competențe scăzute</h3>
                  <p>Procesul educațional desfășurat în instituțiile de învățământ nu se axează pe competențele solicitate de piața muncii.</p>
               </div>
               <div className='hp__program-box purple'>
                  <h2>03</h2>
                  <h3>Acces limitat la experți</h3>
                  <p>Alături de profesori, adolescenții au nevoie să se conecteze cu mentori și specialiști din companii pentru perspective practice.</p>
               </div>
            </div>
            <div className="right-aligned">
               <p className='hp__section-description hp__more-about'><b>PROGRAMUL Skill<span>UP</span></b> își propune să satisfacă nevoia liceenilor de educație practică oferită de specialiști, de dezvoltare de abilități și competențe și de conectare cu mentori.</p>
            </div>

         </div>


         <div className='hp__skillup-programs'id='programs'>
            <div className='hp__single-program'>
               <img src='/images/chenar-photo.jpg' alt='programs' />
               <div className='hp__program-title'>
                  <h3>SkillUp & Business by</h3>
                  <img src='/images/asus-logo.png' alt='logo' />

               </div>
               <p>Programul nostru special dedicat pasionaților de lumea afacerilor îți oferă oportunitatea de a lucra alături de profesioniști, nume importante din domeniul business-ului.</p>
               <p>Participă la SkillUP & Business și vei învăța, ghidat de mentori, cum să-ți transformi ideile în proiecte de succes și ce abilități sunt necesare pentru a deveni un adevărat lider.</p>
               <Link to='/program-skillup-business' className='standard__link' onClick={scrollToTop}> <button><h4>Vezi Programul</h4></button></Link>
            </div>
            <div className='hp__single-program'>
               <img src='/images/program-2.jpeg' alt='programs' />
               <div className='hp__program-title'>
                  <h3>SkillUp & Journalism by</h3>
                  <img className='hp__artesana-logo' src='/images/artesana.svg' alt='logo' />

               </div>
               <p>Programul nostru dedicat celor pasionați de lumea presei și comunicării îți oferă ocazia să lucrezi alături de jurnaliști consacrați și profesioniști din media, descoperind secretele unui conținut captivant și bine documentat.</p>
               <p>Participă la SkillUP & Journalism și vei învăța, ghidat de mentori, ce înseamnă documentare concretă, scriere creativă, utilizarea tehnologiei digitale în media și care este impactul jurnalismului în societate.</p>
               <button><h4>Disponibil curând</h4></button>
            </div>
         </div>

         <div className='hp__resources'>
            <div className='hp__resources-text'>
               <h3><span className='hp__purple'>Resurse educaționale </span>valoroase, toate <span className='hp__pink'>într-un singur loc!</span></h3>
            </div>
            <button>În curând</button>
         </div>

         {/* <div className='hp__pros-section' id='skillup'>
            <div className='hp__skill-title'>
               <h2 className='hp__section-title '>Skill<span>UP</span>& Business</h2>
               <ScrollLink to='chestionar' className='standard__link standard__only-desktop' smooth={true} duration={500}>

                  <button className='hp__banner-button'>Aplică acum</button>
               </ScrollLink>
            </div>

            <div className='hp__skill-right'>
               <p className='hp__section-description'> Experiențe practice oferite de către profesioniști</p>
               <p className='hp__section-description'><b>Target: </b> Elevi de liceu</p>
               <p className='hp__section-description'><b>Grup: </b>  20 liceeni </p>
               <p className='hp__section-description'><b>Durată: </b> 5-10 zile</p>
               <Link to='/' className='standard__link standard__only-mobile'>
                  <button className='hp__banner-button apply-button'>Aplică acum</button>
               </Link>
               <div className="hp__pros-container">
                  <div className='hp__pro hp__pro-title standard__only-mobile'>
                     <h3>Beneficii</h3>

                  </div>
                  <div className="hp__pro hp__pro-yellow">
                     <h4>Business training cu practicieni</h4>
                  </div>
                  <div className="hp__pro hp__pro-blue">
                     <h4>Întâlniri cu antrepernori și experți în business</h4>
                  </div>
                  <div className="hp__pro hp__pro-red">
                     <h4>CV structurat cu expertiza unui specialist HR</h4>
                  </div>
                  <div className="hp__pro hp__pro-blue">
                     <h4>Abilități practice, mentalitate de antreprenor</h4>
                  </div>
                  <div className="hp__pro hp__pro-red">
                     <h4>Înscriere în grup Alumni cu acces gratuit la resurse educaționale</h4>
                  </div>
                  <div className='hp__pro hp__pro-title standard__only-desktop'>
                     <h3>Beneficii</h3>

                  </div>
                  <div className="hp__pro hp__pro-yellow">
                     <h4>Participare la evenimente de business</h4>
                  </div>
                  <div className="hp__pro hp__pro-blue">
                     <h4>la programe de voluntariat consistente și joburi sezoniere</h4>
                  </div>
                  <div className="hp__pro hp__pro-yellow">
                     <h4>Suport și ghidaj oferite de specialiști</h4>
                  </div>
                  <div className="hp__pro hp__pro-blue">
                     <h4>Premii puse la dispoziție de parteneri</h4>
                  </div>
                  <div className="hp__pro hp__pro-red">
                     <h4>O zi cu un CEO sau top management</h4>
                  </div>
               </div>

      </div>
         </div > */}
         {/* <div className='hp__programs-mentors'>
            <div className='hp__programs'>
               <img src='/images/chenar-photo.jpg' alt='photo' className='hp__programs-banner' />
               <div className='hp__programs-title'>
                  <h3>Skill<span>UP</span> & Business by</h3>
                  <img src='/images/asus-logo.png' className='hp__asus-logo' />

               </div>
               <h3 className='hp__programs-info'>Cohorta numărul #1 își deschide porțile pentru a primi 30 de liceeni pentru o experiență practică în domeniul business-ului. </h3>
               <h3 className='hp__programs-info'>Programul nostru autentic îți oferă oportunitatea de a lucra alături de profesioniști și de a primi îndrumare din partea mentorilor, experți în domeniul business-ului.</h3>
               <h3 className='hp__programs-info'>SkillUP & Business va începe la finalul lunii octombrie, hai și tu să faci parte din program
               </h3>
               <div className="hp__apply-button">
                  <button className='hp__banner-button'>       <a href='/documents/program.pdf' target='_blank' rel='noopener noreferrer' className='standard__link'>
                     Vezi programul</a></button>
               </div>
            </div>

            <img src='/images/MENTORII.jpg' alt='mentori' className='hp__mentors-img' />
         </div> */}

         <div className='hp__mentors-section' id='mentori'>
            <h2 className='hp__section-title hp__mentors-title'>Mentorii Skill<span>UP</span>& <span className='yellow-span'>Business</span> </h2>
            <Slider  {...settings} className='hp__slider '>
               {mentorsData.map(mentor => (
                  <div className='hp__mentor' key={mentor.key}>
                     <img src={mentor.imageUrl} alt='mentor' />
                     <h4>{mentor.name}</h4>
                     <h5>{mentor.function}</h5>
                     <h5>{mentor.brand}</h5>
                     <h5><i>{mentor.tags}</i></h5>
                     {mentor.bio && (
                        <h5 type='primary' onClick={() => showModal(mentor)} className='hp__see-bio'>
                           Află mai multe
                        </h5>
                     )}                  </div>
               ))}

            </Slider>

         </div>

         <div className='hp__partners-section' id='parteneri'>
            <h2 className='hp__section-title'>Parteneri</h2>
            <p className='hp__section-description'>Misiunea noastră este să înrolăm cât mai mulți liceeni în programele noastre de educație practică, astfel, avem nevoie de parteneri pe termen lung pentru a susține. Mulțumim celor care au încredere in EduUP.</p>
            <Slider  {...partnerSettings} className='hp__slider standard__only-mobile hp__partner-slider'>
               <img src='/images/asus-logo.png' alt='partener' />
               <img src='/images/logo-artesana.jpg' alt='partner' />
               <img src='/images/partner1.png' alt='partner' />
               <img src='/images/partner2.png' alt='partner' />
               <img src='/images/animawings.jpg' alt='partner' className='hp__animawings' />


            </Slider>
            <div className='hp__partners standard__only-desktop'>
               <img src='/images/asus-logo.png' alt='partener' />
               <img src='/images/logo-artesana.jpg' alt='partner' className='hp__artesana' />
               <img src='/images/partner1.png' alt='partner' />
               <img src='/images/partner2.png' alt='partner' />
               <img src='/images/animawings.jpg' alt='partner' className='hp__animawings' />


            </div>
         </div>

         <div className='hp__meaning hp__meaning-impact' id='impact'>
            <h2 className='hp__section-title hp__meaning-title'>Impact <span>Social</span></h2>
            <div className='hp__impact-left'>
               <p className='hp__section-description'>Pentru a ne asigura că toți adolescenții au aceleași șanse de a participa în <b>programele EduUP</b>, oferim <spam className='yellow-span'>BURSE</spam> pentru fiecare cohortă.</p>
               <h4 className='hp__impact-title'>Eligibilitatea participării gratuite:</h4>
               <ul className="hp__impact-list">
                  <li><b>ESEU</b> de maxim 700 de cuvinte pe tema „Care este relația între aptitudinile tinerilor și capacitatea acestora de a excela în domeniul de activitate ales” </li>
                  <li><b>CV</b> care să includă experiența extracuriculară </li>
                  <li><b>INTERVIU</b> cu echipa EduUP</li>

               </ul>
                  <button className='hp__banner-button bursa-button' onClick={showFormModal}>Aplică acum!</button>
            </div>
            <img src="/images/impact.png" className='standard__only-desktop' />
         </div>

         <div className='hp__faq-section' id="intrebari-frecvente">
            <h2 className='hp__section-title'>Întrebări frecvente</h2>
            <div className='hp__faq-container'>
               <div className='hp__faq-left'>
                  <FAQSection faqs={faqs} />
               </div>
               <div className='hp__faq-right'>
                  <img src='/images/q1.png' />
                  <img src='/images/q2.png' />

               </div>
            </div>
         </div>

         <div className='hp__form-section' id='contact'>
            <img src='/images/form-img.png' className='hp__form-img' />
            <div className='hp__form-right'>
               <h3 className='hp__form-subtitle'>Contactează-ne!</h3>
               <form onSubmit={handleSubmit} className='hp__form' novalidate>
                  <div className='hp__form-box'>
                     <input
                        type="text"
                        name="name"
                        required
                        placeholder="Nume *"
                        value={formData.name}
                        onChange={handleChange}
                     />
                     <input
                        type="text"
                        name="surname"
                        required
                        placeholder="Prenume *"
                        value={formData.surname}
                        onChange={handleChange}
                     />
                  </div>
                  <div className='hp__form-box'>
                     <input
                        type="email"
                        required
                        name="email"
                        placeholder="Email *"
                        value={formData.email}
                        onChange={handleChange}
                     />
                     <input
                        type="number"
                        required
                        name="phone"
                        placeholder="Telefon *"
                        value={formData.phone}
                        onChange={handleChange}
                     />
                  </div>

                     <textarea
                        type="text"
                        rows='5'
                        cols='5'
                        required
                        name="message"
                        placeholder="Mesaj *"
                        value={formData.message}
                        onChange={handleChange}
                     />

                  <div className='hp__checkbox'>
                     <input
                        type="checkbox"
                        name="gdprAgreement"
                        checked={formData.gdprAgreement}
                        onChange={handleChange}
                     />
                     <h5>Sunt de acord cu normele GDPR de prelucrare a datelor personale.</h5>
                  </div>
                  <div className='hp__checkbox'>
                     <input
                        type="checkbox"
                        name="newsletterSubscription"
                        checked={formData.newsletterSubscription}
                        onChange={handleChange}
                     />
                     <h5> Sunt de acord să mă abonez la newsletterul
                        EduUP.</h5>
                  </div>
                  <button type="submit" className={`hp__form-submit ${(submitting || !formData.newsletterSubscription || !formData.gdprAgreement) ? 'disabled' : ''}`}
                     disabled={submitting || !formData.newsletterSubscription || !formData.gdprAgreement}>Înscriere</button>
               </form>
            </div>

         </div>



         {/* <div className="marquee">
            <div className="marquee__inner">
               {mentorsData.map((mentor, index) => (
                  <div className="hp__mentor" key={mentor.key || index}>
                     <img src={mentor.imageUrl} alt='mentor' />
                     <h4>{mentor.name}</h4>
                     <h5>{mentor.function}</h5>
                     <h5>{mentor.brand}</h5>
                     <h5><i>{mentor.tags}</i></h5>
                     {mentor.bio && (
                        <h5 type='primary' onClick={() => showModal(mentor)} className='hp__see-bio'>
                           Află mai multe
                        </h5>
                     )}
                  </div>
               ))}

            </div>
         </div> */}




         <div className='hp__footer'>
            <div className='hp__footer-column'>
               <img src='/images/logo-white.png' alt='logo' className='hp__footer-logo' />
               <div className='hp__footer-social'>
                  <a href="https://www.facebook.com/asociatiaeduup/" target="_blank" className='standard__link'>
                     <img src='/images/facebook.png' alt='facebook' />
                  </a>
                  <a href="https://www.instagram.com/eduup.ro/" target="_blank" className='standard__link'>
                     <img src='/images/instagram.png' alt='instagram' />
                  </a>
                  <a href="https://www.linkedin.com/company/asociatia-eduup/" target="_blank" className='standard__link'>
                     <img src='/images/linkedin.png' alt='linkedin' />
                  </a>
                  <a href="https://www.youtube.com/@AsociatiaEduUP" target="_blank" className='standard__link'>
                     <img src='/images/youtube.png' alt='youtube' />
                  </a>

               </div>
            </div>

            <div className='hp__footer-column'>
               <h4>EduUp</h4>
               <a href='/documents/regulament-oficial-concurs.pdf' target='_blank' rel='noopener noreferrer' className='standard__link'>
                  <h5>Regulament concurs Summer Well 2024
                  </h5>
               </a>
               <ScrollLink to='despre' className='standard__link' smooth={true} duration={500}>
                  <h5>Despre EduUp</h5>
               </ScrollLink>
               <ScrollLink to='ce-inseamna' className='standard__link' smooth={true} duration={500}>
                  <h5>Ce înseamnă EduUp</h5>
               </ScrollLink>
            </div>

            <div className='hp__footer-column'>
               <h4>SkillUp</h4>
               <ScrollLink to='skillup' className='standard__link' smooth={true} duration={500}>
                  <h5>Despre SkillUp</h5>
               </ScrollLink>
               <ScrollLink to='mentori' className='standard__link' smooth={true} duration={500}>
                  <h5>SkillUp & Business</h5>
               </ScrollLink>
               <ScrollLink to='mentori' className='standard__link' smooth={true} duration={500}>
                  <h5>Mentori</h5>
               </ScrollLink>
            </div>

            <div className='hp__footer-column'>
               <ScrollLink to='impact' className='standard__link' smooth={true} duration={500}>
                  <h5>Impact Social</h5>
               </ScrollLink>
               <ScrollLink to='parteneri' className='standard__link' smooth={true} duration={500}>
                  <h5>Parteneri</h5>
               </ScrollLink>
               <ScrollLink to='intrebari-frecvente' className='standard__link' smooth={true} duration={500}>
                  <h5>Întrebări frecvente</h5>
               </ScrollLink>
            </div>

            <div className='hp__footer-column'>
               <h4>Contact</h4>
               <h5>hello@eduup.ro</h5>
               <a className='standard__link' href="tel:+40722280027">
                  <h5>+40722280027</h5>
               </a>
               <a href='https://oceanobe.com/' target='_blank' className='standard__link hp__oceanobe'>
                  <h5>Web platform done by the engineering experts @ OceanoBe Technology.</h5>
               </a>
            </div>

            <Modal
               title={selectedMentor ? selectedMentor.name : ''}
               visible={isModalVisible}
               onCancel={handleCancel}
               footer={[
                  <Button key="close" onClick={handleCancel}>
                     Închide
                  </Button>,
               ]}
            >
               {selectedMentor && (
                  <>
                     <p className='hp__mentor-bio'>{selectedMentor.bio}</p>
                     <a href={selectedMentor.linkedinUrl} target="_blank" rel="noopener noreferrer">
                        Vezi profilul LinkedIn
                     </a>
                  </>
               )}
            </Modal>
            {isModalOpen && <ImpactForm isOpen={isModalOpen} onClose={closeModal} />}

         </div>
      </div >
   )
}
export default Homepage
