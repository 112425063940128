import React, { useState } from 'react';
import './MobileMenu.scss';
import { Link as ScrollLink } from 'react-scroll';
import { Link, useNavigate } from 'react-router-dom';

const MobileMenu = () => {
    const [activeMenu, setActiveMenu] = useState(false);
    const navigate = useNavigate();

    const scrollToElementOnHome = (sectionId) => {
        navigate('/', { state: { scrollTo: sectionId } });
    };

    const openMenu = () => {
        setActiveMenu(true);
        document.body.classList.add('has-active-menu');
    };

    const closeMenu = () => {
        setActiveMenu(false);
        document.body.classList.remove('has-active-menu');
    };

    return (
        <div className="standard hamburger">
            <img
                onClick={openMenu}
                className="hamburger__icon"
                src="/images/menu.png"
                alt="hamburgerMenuLogo"
            />
            <ul
                className={
                    'hamburger__links' + (activeMenu ? ' active' : '')
                }
            >
                <div className='hamburger__links-list'>
                    <img src="/images/logo-white.png" alt='logo' className='hamburger__logo' />
                    <ScrollLink
                        className='hamburger__link'
                        to='despre'
                        smooth={true}
                        duration={500}
                        onClick={() => {
                            closeMenu();
                            scrollToElementOnHome('despre');
                        }}
                    >
                        <li className='hamburger__list-item'>Despre</li>
                    </ScrollLink>
                    <ScrollLink
                        className='hamburger__link'
                        to='ce-inseamna'
                        smooth={true}
                        duration={500}
                        onClick={() => {
                            closeMenu();
                            scrollToElementOnHome('ce-inseamna');
                        }}
                    >
                        <li className='hamburger__list-item'>Ce înseamnă</li>
                    </ScrollLink>
                    <Link
                        className='hamburger__link'
                        to='/program-skillup-business'
                        onClick={closeMenu}
                    >
                        <li className='hamburger__list-item'>Program Business</li>
                    </Link>
                    <ScrollLink
                        className='hamburger__link'
                        to='mentori'
                        smooth={true}
                        duration={500}
                        onClick={() => {
                            closeMenu();
                            scrollToElementOnHome('mentori');
                        }}
                    >
                        <li className='hamburger__list-item'>Mentori</li>
                    </ScrollLink>
                    <ScrollLink
                        className='hamburger__link'
                        to='impact'
                        smooth={true}
                        duration={500}
                        onClick={() => {
                            closeMenu();
                            scrollToElementOnHome('impact');
                        }}
                    >
                        <li className='hamburger__list-item'>Impact social</li>
                    </ScrollLink>
                    <ScrollLink
                        className='hamburger__link'
                        to='parteneri'
                        smooth={true}
                        duration={500}
                        onClick={() => {
                            closeMenu();
                            scrollToElementOnHome('parteneri');
                        }}
                    >
                        <li className='hamburger__list-item'>Parteneri</li>
                    </ScrollLink>
                    <ScrollLink
                        className='hamburger__link'
                        to='intrebari-frecvente'
                        smooth={true}
                        duration={500}
                        onClick={() => {
                            closeMenu();
                            scrollToElementOnHome('intrebari-frecvente');
                        }}
                    >
                        <li className='hamburger__list-item'>FAQ</li>
                    </ScrollLink>
                    <Link
                        className='hamburger__link'
                        to='/blog'
                        onClick={closeMenu}
                    >
                        <li className='hamburger__list-item'>Blog</li>
                    </Link>
                </div>
            </ul>
            <img
                onClick={closeMenu}
                className={
                    'hamburger__close-icon' + (activeMenu ? ' active' : '')
                }
                src="/images/close-icon.png"
                alt="close Icon"
            />
        </div>
    );
};

export default MobileMenu;