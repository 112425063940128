import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import InputLabel from '@material-ui/core/InputLabel';
import './AttachementField.scss';

class AttachementField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewUrl: props.document?.url || null
    };
  }

  componentDidUpdate(prevProps) {
    // Check if document prop has changed
    if (prevProps.document?.url !== this.props.document?.url) {
      this.setState({ previewUrl: this.props.document?.url });
    }
  }

  handleDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      console.log('Preview URL:', reader.result);
      this.setState({ previewUrl: reader.result });
    };

    if (file) {
      reader.readAsDataURL(file);
    }

    this.props.onDrop(acceptedFiles);
  }

  handleRemove = () => {
    this.setState({ previewUrl: null });
    this.props.removeDocument();
  }
  

  render() {
    const dropzoneStyle = {
      width: '100%',
      height: '70px',
      cursor: 'pointer',
      marginBottom: '50px'
    };

    const dropzoneDisabledStyle = {
      width: '100%',
      height: '100%',
      borderRadius: 5,
      background: 'rgba(255, 255, 255, .5)',
      paddingLeft: '10px'
    };

    return (
      <div className="attachment-form__upload-content">
        <InputLabel>{this.props.attachmentLabel}</InputLabel>
        <Dropzone
          accept={this.props.type}
          multiple={false}
          onDrop={this.handleDrop}
          disabled={this.props.disabledDocuments}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()} style={this.props.disabledDocuments ? dropzoneDisabledStyle : dropzoneStyle}>
                <input {...getInputProps()} />
                <div className="attachment-form__upload-files">
                  {!this.state.previewUrl && (
                    <div className="attachment-form__instruction">
                      <h3 className='attachment-form__upload'>{this.props.text}</h3>
                      <img src='/images/file.png' alt='file' className='attachment-form__icon' />
                      <h4 className='attachment-form__add'>
                        Adaugă imagine
                      </h4>
                    </div>
                  )}
                  {this.state.previewUrl && (
                    <div className="attachment-form__existing-files">
                      <ul className="attachment-form__file-list">
                        <li key={this.props.document?.name || this.props.documentFilename}>
                          {this.props.document?.name || this.props.documentFilename}
                        </li>
                      </ul>
                      <div className="attachment-form__remove-files">
                        <button
                          type="button"
                          className="attachment-form__delete-button"
                          onClick={(event) => { event.stopPropagation(); this.handleRemove() }}
                        >
                          <h5 className='attachment-form__delete'>
                            Șterge fișier
                          </h5>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </section>
          )}
        </Dropzone>
        {/* {this.state.previewUrl && (
          <div className="attachment-form__preview">
            <img src={this.state.previewUrl.data} alt="Preview" className="attachment-form__image-preview" />
          </div>
        )} */}
      </div>
    );
  }
}

export default AttachementField;